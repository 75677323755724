

export const imageOptions = [
    {
        name: 'World Edge',
        path: '../../../assets/media/images/world-edge.jpg'
    },
    {
        name: 'Bubble Sun Roots',
        path: '../../../assets/media/images/bubble-roots.jpg'
    },
    {
        name: 'Crack the Ground',
        path: '../../../assets/media/images/crack-the-ground.jpg'
    },
    {
        name: 'Forest Sun',
        path: '../../../assets/media/images/forest-sun.jpg'
    },
    {
        name: 'Mineral Stars',
        path: '../../../assets/media/images/mineral-stars.jpg'
    },
    {
        name: 'Sunset',
        path: '../../../assets/media/images/sunset.jpg'
    },
    {
        name: 'Lightning Triangle',
        path: '../../../assets/media/images/lightning-triangles.jpg'
    }
]
