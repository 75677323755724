<mat-toolbar #toolbar id="toolbar">
    <div [ngClass]="{ 'hidden': !toolbarService.showBackClick }" class="icon-button back-button x-small"  (click)="backClick()">
        <span class="iconify" data-icon="mdi:arrow-left" data-inline="false"></span>
    </div>
    <div class="drawnbro" (click)="clickLogo()">
        <div class="drawnbro-logo-container">
           <img [src]="'../../../assets/media/logos/drawnbro_logo_100px.png'" class="drawnbro-logo"> 
        </div>
        <span>drawnbro</span>
    </div>

    <span class="toolbar-spacer"></span>
</mat-toolbar>