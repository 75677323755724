import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { imageOptions } from './soon.constants';

export interface ImageOption {
  name: string,
  path: string
}

export enum ImageStateDirection {
  INCREMENTING='incrementing',
  DECREMENTING='decrementing',
}

export interface ImageState {
  opacity: number,
  direction: ImageStateDirection
}

@Component({
  selector: 'app-soon',
  templateUrl: './soon.component.html',
  styleUrls: ['./soon.component.scss']
})
export class SoonComponent implements OnInit, AfterViewInit {

  @ViewChild('imageCard') imageCard: ElementRef;

  public imageState: ImageState;
  public currentIndex = 0;
  public imagesOpts = imageOptions;
  public currentImage = this.imagesOpts[this.currentIndex];
  public imageCyclingMS = 30000;
  public imageTransitionDelayMS = 25;
  public imageTransitionIncrementOpacity = 0.05;

  constructor() { }

  public ngOnInit(): void {
  }

  public ngAfterViewInit(): void {
    this.updateImage();
  }

  public openNewTab(url: string): void {
    window.open(url, '_blank');
  }

  private cycleImage(): void {
    if (this.currentIndex + 1 >=  this.imagesOpts.length) {
      this.currentIndex = 0;
    } else {
      this.currentIndex += 1;
    }
    this.imageState = {
      opacity: 1,
      direction: ImageStateDirection.DECREMENTING
    };
    this.updateImage();
  }

  public selectImage(image: ImageOption): void {
    let newIndex = this.currentIndex;
    for (let index = 0; index < this.imagesOpts.length; index++) {
      if (this.imagesOpts[index].name === image.name) {
        newIndex = index;
        break;
      }
    }
    this.currentIndex = newIndex;
    this.setImage();
  }

  public isImageSelected(image: ImageOption): boolean {
    let imageIndex = null;
    for (let index = 0; index < this.imagesOpts.length; index++) {
      if (this.imagesOpts[index].name === image.name) {
        imageIndex = index;
        break;
      }
    }
    return imageIndex === this.currentIndex;
  }

  private setImage(extraStyle: string = ''): void {
    this.currentImage = this.imagesOpts[this.currentIndex];
    this.imageCard?.nativeElement.setAttribute('style', `${extraStyle} background: url('${this.currentImage.path}');`);
  }

  private async updateImage(): Promise<void> {
    if (this.imageState === undefined) {
      // Initialize image
      this.setImage();
      setTimeout(() => this.cycleImage(), this.imageCyclingMS);
      return;
    }

    if (this.imageState.direction === ImageStateDirection.DECREMENTING) {
      if (this.imageState.opacity > 0) {
        // Decrement opacity
        this.imageState.opacity -= this.imageTransitionIncrementOpacity;
        setTimeout(() => this.updateImage(), this.imageTransitionDelayMS)
        return;
      }

      // Finished decrementing need to swap image and begin incrementing
      this.imageState.direction = ImageStateDirection.INCREMENTING;
      this.setImage(`opacity: 0;`);
      setTimeout(() => this.updateImage(), this.imageTransitionDelayMS);
    } else if (this.imageState.direction === ImageStateDirection.INCREMENTING) {
      if (this.imageState.opacity < 1) {
        // Increment opacity
        this.imageState.opacity += this.imageTransitionIncrementOpacity;
        setTimeout(() => this.updateImage(), this.imageTransitionDelayMS)
        return;
      }

      // Finished incrementing
      setTimeout(() => this.cycleImage(), this.imageCyclingMS);
    }
  }
}
