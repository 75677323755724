<!-- Footer -->
<footer class="footer page-footer font-small blue pt-4">

  <!-- Footer Links -->
  <div class="container-fluid text-center text-md-left">

    <!-- Grid row -->
    <div class="row">

      <!-- Grid column -->
      <div class="col">

        <h5 class="text-uppercase footer-title">Drawn to creation...</h5>

        <div class="drawnbro" (click)="clickLogo()">
            <div class="drawnbro-logo-container">
              <img [src]="'../../../assets/media/logos/drawnbro_logo_100px.png'" class="drawnbro-logo"> 
            </div>
            <span>drawnbro</span>
        </div>

        <!-- Social Media Overlay Links -->
        <div class="social-media-icons footer-social-media-icons">
          <div class="icon-button x-small" (click)="openNewTab('https://www.redbubble.com/people/drawnbro/shop')" title="Redbubble">
            <span class="iconify" data-icon="bx:bxl-redbubble" data-inline="false"></span>
          </div>
          <div class="icon-button x-small" (click)="openNewTab('https://drawnbro.myspreadshop.com/')" title="SpreadShirt">
            <span class="iconify" data-icon="simple-icons:spreadshirt" data-inline="false"></span>
          </div>
          <div class="icon-button x-small" (click)="openNewTab('https://www.instagram.com/drawnbro/')" title="Instagram">
              <span class="iconify" data-icon="mdi:instagram" data-inline="false"></span>
          </div>
          <div class="icon-button x-small" (click)="openNewTab('https://twitter.com/drawnbro/')" title="Twitter">
              <span class="iconify" data-icon="mdi:twitter" data-inline="false"></span>
          </div>
          <div class="icon-button x-small" (click)="openNewTab('https://www.youtube.com/channel/UCTyMtxEftFrNGuH9VO2U6DQ')" title="Youtube">
            <span class="iconify" data-icon="mdi:youtube" data-inline="false"></span>
          </div>
          <!-- <div class="icon-button x-small" (click)="openNewTab('https://www.twitch.tv/drawnbro')" title="Twitch">
              <span class="iconify" data-icon="mdi:twitch" data-inline="false"></span>
          </div> -->
        </div>
      </div>

      <!-- Grid column -->
      <div class="col">
        <ul class="list-unstyled privacy-terms-list">
          <li>
            <a href="/privacy">Privacy Policy</a>
          </li>
          <li>
            <a href="/terms">Terms of Service</a>
          </li>
        </ul>
      </div>

    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">© 2020 Copyright:
    <a href="https://www.drawnbro.com/">www.drawnbro.com</a>
  </div>
  <!-- Copyright -->

</footer>
<!-- Footer -->