import { Component, ElementRef, HostListener, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { galleryItems } from 'src/app/models/gallery.constants';
import { GalleryItem } from 'src/app/models/gallery.model';
import { ToolbarService } from 'src/app/services/toolbar.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  public galleryItems: GalleryItem[] = galleryItems.reverse();

  public mintableColumns: string[] = ['edition', 'tokenId', 'shop'];

  public previouslySelected: GalleryItem = null;
  public selectedItem: GalleryItem = null;
  public selectedItemId: string = null;

  @ViewChildren('itemCard') itemCards: ElementRef[];
  @ViewChild('ancor') ancor: ElementRef;

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    // On back button pressed
    setTimeout(() => this.loadData())
  }

  constructor(
    public toolbarService: ToolbarService,
    public route: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  public loadData(): void {
    this.toolbarService.setBackClickHandler(() => this.deselectItem());
    
    this.route.queryParams.subscribe(params => {
      this.selectedItemId = params['item'];
      if (this.selectedItemId) {
        this.selectItemById();
      } else {
        this.selectedItem = null;
        this.toolbarService.showBackClick = false;
      }
    });
  }

  public selectItemById(navigate: boolean = true): void {
    const item = this.galleryItems.find(galleryItem => galleryItem.id === this.selectedItemId);
    if (item) {
      this.selectItem(item, navigate);
    }
  }

  public selectItem(item: GalleryItem, navigate: boolean = true): void {
    this.selectedItem = item;
    this.previouslySelected = JSON.parse(JSON.stringify(this.selectedItem));
    this.toolbarService.showBackClick = true;
    if (navigate) {
      this.router.navigate([], { relativeTo: this.route, queryParams: {
        item: item.id
      } })
    }
    setTimeout(() => this.scrollToTop());
  }

  public scrollToTop(): void {
    this.ancor.nativeElement.scrollIntoView();
  }

  public deselectItem(): void {
    this.previouslySelected = JSON.parse(JSON.stringify(this.selectedItem));
    this.selectedItem = null;
    this.selectedItemId = null;
    this.toolbarService.showBackClick = false;
    this.router.navigate([], { relativeTo: this.route })
    setTimeout(() => this.scrollToCardItem(this.previouslySelected.id));
  }

  public scrollToCardItem(itemId: string): void {
    const cardItem = this.itemCards.find(card => card.nativeElement.value === itemId)
    cardItem.nativeElement.scrollIntoView();
  }

  public openNewTab(url: string): void {
    window.open(url, '_blank');
  }

  public areSocialMediaIconsVisible(): boolean {
    return !!this.selectedItem?.metadata?.instagram
      || !!this.selectedItem?.metadata?.twitch
      || !!this.selectedItem?.metadata?.twitter;
  }

  public areShopsIconsVisible(): boolean {
    return !!this.selectedItem?.metadata?.redbubble;
  }
}
