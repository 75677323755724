<div class="home-container">
    <div class="left-container">
        <span class="coming-soon-text">drawnbro, coming soon...</span>
        <!-- Social Media Overlay Links -->
        <div class="social-media-icons">
            <div class="instagram-icon"  (click)="openNewTab('https://www.instagram.com/drawnbro/')">
                <span class="iconify" data-icon="mdi:instagram" data-inline="false"></span>
            </div>
            <div class="twitch-icon" (click)="openNewTab('https://www.twitch.tv/drawnbro')">
                <span class="iconify" data-icon="mdi:twitch" data-inline="false"></span>
            </div>
        </div>
    </div>
    <div class="right-container">

        <!-- Image -->
        <div class="coming-soon-card" #imageCard [style.opacity]="imageState === undefined ? 1 : imageState?.opacity">
        </div>        
        
        <div class="gallery-list" *ngIf="imagesOpts?.length > 0">
            <div *ngFor="let image of imagesOpts" class="image-bubble" (click)="selectImage(image)" [class.selected]="isImageSelected(image)"></div>
        </div>
    </div>
</div>
