<div class="gallery-container">
    <div #ancor></div>
    <div *ngIf="!selectedItem" class="card-list">
        <div *ngFor="let item of galleryItems" #itemCard class="item-card" (click)="selectItem(item)" [value]="item.id">
            <div class="card-image-container">
                <img [src]="'../../../assets/media/thumbnails/' + item.thumbnail" alt="card-image" class="card-image">
            </div>
            <div class="card-name-container">
                <span class="card-name">{{item.name}}</span>
            </div>

            <div class="info-bubble" matTooltip="Click for more information">
                <span class="iconify" data-icon="mdi:information-outline" data-inline="false"></span>
            </div>
            
        </div>
    </div>

    <div *ngIf="selectedItem" class="item-showcase">
        <div class="item-showcase-container">
            <img [src]="'../../../assets/media/images/' + selectedItem.thumbnail" alt="card-image" class="showcase-image">

            <div class="item-showcase-details-container">
                <div class="item-info">
                    <span *ngIf="selectedItem?.name" class="item-showcase-name">
                        {{ selectedItem.name }}
                    </span>
                    <span *ngIf="selectedItem?.description" class="item-showcase-description">
                        "{{ selectedItem?.description }}"
                    </span>

                    <div *ngIf="areSocialMediaIconsVisible()" class="social-media-icons-container">
                        <span  class="item-showcase-header">
                            Related posts:
                        </span>
    
                        <div class="social-media-icons">
                            <div *ngIf="selectedItem?.metadata?.instagram" class="icon-button"  (click)="openNewTab(selectedItem.metadata.instagram)">
                                <span class="iconify" data-icon="mdi:instagram" data-inline="false" title="Instagram"></span>
                            </div>
                            <div *ngIf="selectedItem?.metadata?.twitter" class="icon-button" (click)="openNewTab(selectedItem.metadata.twitter)">
                                <span class="iconify" data-icon="mdi:twitter" data-inline="false" title="Twitter"></span>
                            </div>
                            <div *ngIf="selectedItem?.metadata?.youtube" class="icon-button" (click)="openNewTab(selectedItem.metadata.youtube)">
                                <span class="iconify" data-icon="mdi:youtube" data-inline="false" title="Youtube"></span>
                            </div>
                            <div *ngIf="selectedItem?.metadata?.twitch" class="icon-button" (click)="openNewTab(selectedItem.metadata.twitch)">
                                <span class="iconify" data-icon="mdi:twitch" data-inline="false" title="Twitch"></span>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="areShopsIconsVisible()" class="social-media-icons-container">
                        <span  class="item-showcase-header">
                            Merchandise shop:
                        </span>
    
                        <div class="social-media-icons">
                            <div
                                *ngIf="selectedItem?.metadata?.redbubble"
                                class="icon-button"
                                (click)="openNewTab(selectedItem.metadata.redbubble)"
                                title="Redbubble"
                                >
                                <span class="iconify" data-icon="bx:bxl-redbubble" data-inline="false"></span>
                            </div>
                            <div
                                *ngIf="selectedItem?.metadata?.spreadshirt"
                                class="icon-button"
                                (click)="openNewTab(selectedItem.metadata.spreadshirt)"
                                title="SpreadShirt"
                                >
                                <span class="iconify" data-icon="simple-icons:spreadshirt" data-inline="false"></span>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="selectedItem?.metadata?.mintable?.length" class="mintable-table-container">
                        <span class="item-showcase-header">
                            NFTs:
                        </span>

                        <table mat-table [dataSource]="selectedItem.metadata.mintable" class="mintable-table" theme="primary">

                            <!-- Edition Column -->
                            <ng-container matColumnDef="edition">
                                <th mat-header-cell *matHeaderCellDef> Edition </th>
                                <td mat-cell *matCellDef="let element"> {{element.editionNumber}} / {{element.editionTotal}} </td>
                            </ng-container>
                            
                            <!-- Token Id Column -->
                            <ng-container matColumnDef="tokenId">
                                <th mat-header-cell *matHeaderCellDef> Token ID </th>
                                <td mat-cell *matCellDef="let element"> {{element.tokenId}} </td>
                            </ng-container>
                            
                            <!-- Shop Link Column -->
                            <ng-container matColumnDef="shop">
                                <th mat-header-cell *matHeaderCellDef> Shop </th>
                                <td mat-cell *matCellDef="let element">
                                    <div class="icon-button small mintable-button" (click)="openNewTab(element.mintableUrl)" title="Mintable">
                                        <span class="iconify" data-icon="mdi:cart-outline" data-inline="false"></span>
                                    </div>
                                </td>
                            </ng-container>
                        
                            <tr mat-header-row *matHeaderRowDef="mintableColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: mintableColumns;"></tr>
                        </table>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>

</div>