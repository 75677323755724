import { GalleryItem } from "./gallery.model";

export const galleryItems: GalleryItem[] = [
    {
        id: '5bff9464-8577-42e6-96fd-cc18bb829c15',
        name: 'World Edge',
        description: 'We cast our shadow on the world.',
        thumbnail: 'world-edge.jpg',
        metadata: {
            instagram: 'https://www.instagram.com/p/CHjiEDjDAMM/',
            twitter: 'https://twitter.com/drawnbro/status/1378016589690368010',
            redbubble: 'https://www.redbubble.com/shop/ap/89854384',
            mintable: [
                {
                    editionNumber: 1,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495844138515636088182795602634505',
                    mintableUrl: 'https://mintable.app/art/item/World-Edge-Genesis---drawnbro-original/ktvn_24lmU4OTLQ'
                },
                {
                    editionNumber: 2,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495869242979279668914386534553416',
                    mintableUrl: 'https://mintable.app/art/item/World-Edge-Genesis---drawnbro-original/5rf-rOPzCejerQf'
                },
                {
                    editionNumber: 3,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495868970875097515592230192056629',
                    mintableUrl: 'https://mintable.app/art/item/World-Edge-Genesis---drawnbro-original/5mlAOhj8aHeX06R'
                },
                {
                    editionNumber: 4,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495852436879787842166438856062614',
                    mintableUrl: 'https://mintable.app/art/item/World-Edge-Genesis---drawnbro-original/2colneziawh2KPl'
                },
                {
                    editionNumber: 5,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495839423013740161657488592486998',
                    mintableUrl: 'https://mintable.app/art/item/World-Edge-Genesis---drawnbro-original/x6nO_AxacZ9DS1R'
                },
                {
                    
                    editionNumber: 6,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495857691639057448685276046197904',
                    mintableUrl: 'https://mintable.app/art/item/World-Edge-Genesis---drawnbro-original/-F3eR9SI4vbZ2Zu'
                },
                {
                    
                    editionNumber: 7,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495837720765333043230883162100097',
                    mintableUrl: 'https://mintable.app/art/item/World-Edge-Genesis---drawnbro-original/RVxV4kU9Is5V8Zs'
                },
                {
                    
                    editionNumber: 8,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495832305696164908552404356637808',
                    mintableUrl: 'https://mintable.app/art/item/World-Edge-Genesis---drawnbro-original/Fqfq6Cl726f7t4e'
                },
                {
                    
                    editionNumber: 9,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495872858121100311282942566820163',
                    mintableUrl: 'https://mintable.app/art/item/World-Edge-Genesis---drawnbro-original/10dD1ehnMt4xZGE'
                },
                {
                    
                    editionNumber: 10,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495839587126668905973864115624290',
                    mintableUrl: 'https://mintable.app/art/item/World-Edge-Genesis---drawnbro-original/FG-sBZHzyH1X4_K'
                }
            ]
        }
    },
    {
        id: '43a36daa-4d5e-45e6-b862-de0d9297d640',
        name: 'Bubble Sun Roots',
        description: 'We see the exterior as external, while forgetting that we are part of it.',
        thumbnail: 'bubble-roots.jpg',
        metadata: {
            instagram: 'https://www.instagram.com/p/CH32H89sM9o/',
            twitter: 'https://twitter.com/drawnbro/status/1379508225914077184',
            redbubble: 'https://www.redbubble.com/shop/ap/89845094'
        }
    },
    {
        id: 'd6cf6d2c-2137-45bd-8355-d1263c2b4932',
        name: 'Mineral Stars',
        description: 'For those that never left the cave, the ceiling is the sky.',
        thumbnail: '/mineral-stars.jpg',
        metadata: {
            instagram: 'https://www.instagram.com/p/CHtnRkPjuY_/',
            twitter: 'https://twitter.com/drawnbro/status/1378030993404203011',
            redbubble: 'https://www.redbubble.com/studio/promote/89854900'
        }
    },
    {
        id: 'eb377ae4-cd73-4957-8138-a5cec39c64e8',
        name: 'Forest Sun',
        description: 'The sun shines through the forest trees.',
        thumbnail: 'forest-sun.jpg',
        metadata: {
            instagram: 'https://www.instagram.com/p/CIG_CEVsmEW/',
            twitter: 'https://twitter.com/drawnbro/status/1383781246333947907',
            redbubble: 'https://www.redbubble.com/shop/ap/89846915',
        }
    },
    {
        id: '770a6d6d-06b7-4599-8305-cb0831d53f36',
        name: 'Sunset Over the Sea',
        description: 'The sunset over the sea as the water swooshes past.',
        thumbnail: 'sunset.jpg',
        metadata: {
            instagram: 'https://www.instagram.com/p/CKFG3wPM7kM/',
            twitter: 'https://twitter.com/drawnbro/status/1384186416679178254',
            redbubble: 'https://www.redbubble.com/shop/ap/89847521'
        }
    },
    {
        id:'0d00a07e-b7f1-4c67-a503-31f623ff39ac',
        name: 'Crack the Ground',
        description: 'Just because we have the power does not mean we should use it\'s full force',
        thumbnail: 'crack-the-ground.jpg',
        metadata: {
            instagram: 'https://www.instagram.com/p/CKHO0FrMP4P/',
            twitter: 'https://twitter.com/drawnbro/status/1387020618479120387',
        }
    },
    {
        id: 'a9abc859-619d-4bea-98cd-fe4e77ab48b1',
        name: 'Lightning Triangle',
        description: 'When the stars align and planets collide.',
        thumbnail: 'lightning-triangles.jpg',
        metadata: {
            instagram: 'https://www.instagram.com/p/CMpMluYsq7s/',
            twitter: 'https://twitter.com/drawnbro/status/1388091654280724480',
            redbubble: 'https://www.redbubble.com/shop/ap/89844322',
            mintable: [
                {
                    editionNumber: 1,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495834179847672068853591727690866',
                    mintableUrl: 'https://mintable.app/art/item/Stars-Aligned-drawnbro-original/B3rrEfyC3v2-UoZ'
                },
                {
                    editionNumber: 2,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495867291176256947918507432376377',
                    mintableUrl: 'https://mintable.app/art/item/Stars-Aligned-drawnbro-original/i7rYP3-_VNKBEZN'
                },
                {
                    editionNumber: 3,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495857797389575488957101005734402',
                    mintableUrl: 'https://mintable.app/art/item/Stars-Aligned-drawnbro-original/esUr2AfjoZWCsN_'
                },
                {
                    editionNumber: 4,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495854657676571546857103517378673',
                    mintableUrl: 'https://mintable.app/art/item/Stars-Aligned-drawnbro-original/FkSpRImC3iuBnDy'
                },
                {
                    editionNumber: 5,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495852827599539210479086462014517',
                    mintableUrl: 'https://mintable.app/art/item/Stars-Aligned-drawnbro-original/BZD9noapL2tpdWV'
                },
                {
                    editionNumber: 6,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495842262480097733306678329475170',
                    mintableUrl: 'https://mintable.app/art/item/Stars-Aligned-drawnbro-original/-DCdzsDpUDMECOe'
                },
                {
                    editionNumber: 7,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495857461170092564279589411631250',
                    mintableUrl: 'https://mintable.app/art/item/Stars-Aligned-drawnbro-original/Cq-00txjE3EyRqk'
                },
                {
                    editionNumber: 8,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495833941755421074941050314191920',
                    mintableUrl: 'https://mintable.app/art/item/Stars-Aligned-drawnbro-original/RbZjKLDyB362op-'
                },
                {
                    editionNumber: 9,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495838528929828103074975105255234',
                    mintableUrl: 'https://mintable.app/art/item/Stars-Aligned-drawnbro-original/5puqkzKTMNclC5y'
                },
                {
                    editionNumber: 10,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495877488230841347449698330806598',
                    mintableUrl: 'https://mintable.app/art/item/Stars-Aligned-drawnbro-original/A3YiaO5EpZDPQbw'
                }
            ]
        }
    },
    {
        id: 'eb9f9f1d-9e99-4194-b2f5-3d080b2821fa',
        name: 'Abstract Cloud Wolf',
        description: 'Minds shape the abstract to the familiar.',
        thumbnail: 'wolf-cloud.jpg',
        metadata: {
            instagram: 'https://www.instagram.com/p/CNzzzuIMP4m/',
            twitter: 'https://twitter.com/drawnbro/status/1393210863599902721',
        }
    },
    {
        id: '5c59579b-7a2b-4fd3-b8d0-2abdbad294d7',
        name: 'Fragmented Sky',
        description: 'Energy connects the universe.',
        thumbnail: 'fragmented-sky.jpg',
        metadata: {
            instagram: 'https://www.instagram.com/p/COAmCM8sD-8/',
            twitter: 'https://twitter.com/drawnbro/status/1395696070521507842',
            redbubble: 'https://www.redbubble.com/studio/promote/89855594?ref=uploader-to-promote&visit_status=first-time'
        }
    },
    {
        id: '1ad670b0-5d91-44d4-8663-628500de5570',
        name: 'Sunset Mountains',
        description: 'The light through rock.',
        thumbnail: 'sunset-mountains.jpg',
        metadata: {
            instagram: 'https://www.instagram.com/p/CRWA194MTB4/',
            twitter: 'https://twitter.com/drawnbro/status/1415617305883648003',
            youtube: 'https://www.youtube.com/watch?v=ZO_6y66CYok&t=7s',
            redbubble: 'https://www.redbubble.com/studio/promote/89856278'
        }
    },
    {
        id: '3cdf6ae1-b012-4c71-a0b5-c04e4532beec',
        name: 'Stepping Stones',
        description: 'Step by step to the gate in the sky.',
        thumbnail: 'stepping-stones.jpg',
        metadata: {
            instagram: 'https://www.instagram.com/p/CULGl8MM6iH/',
            twitter: 'https://twitter.com/drawnbro/status/1441103925382287365',
            redbubble: 'https://www.redbubble.com/shop/ap/89843429',
            spreadshirt: 'https://drawnbro.myspreadshop.com/stepping+stones?idea=615b1720a91ec51c38259624',
            mintable: [
                {
                    editionNumber: 1,
                    editionTotal: 5,
                    tokenId: '8407815826773092460012303343761068171588464495863291366941710922442881844758',
                    mintableUrl: 'https://mintable.app/Art/item/Stepping-Stones-step-by-step---drawnbro-original/CyGTPibt2X2vhFJ'
                },
                {
                    editionNumber: 2,
                    editionTotal: 5,
                    tokenId: '8407815826773092460012303343761068171588464495843808596351493694127707919700',
                    mintableUrl: 'https://mintable.app/Art/item/Stepping-Stones-step-by-step---drawnbro-original/OkzxV-vM8UvRU6q'
                },
                {
                    editionNumber: 3,
                    editionTotal: 5,
                    tokenId: '8407815826773092460012303343761068171588464495834936146945636004155909812582',
                    mintableUrl: 'https://mintable.app/Art/item/Stepping-Stones-step-by-step---drawnbro-original/z5tc3bPuPQjEL3W'
                },
                {
                    editionNumber: 4,
                    editionTotal: 5,
                    tokenId: '8407815826773092460012303343761068171588464495863651035899158758181966418275',
                    mintableUrl: 'https://mintable.app/Art/item/Stepping-Stones-step-by-step---drawnbro-original/Z_-9tTl99iz-W2O'
                },
                {
                    editionNumber: 5,
                    editionTotal: 5,
                    tokenId: '8407815826773092460012303343761068171588464495872533914082800670687290720868',
                    mintableUrl: 'https://mintable.app/Art/item/Stepping-Stones-step-by-step---drawnbro-original/kQdV1HvtsWdlNTM'
                }
            ]
        }
    },
    {
        id: '3a55a0ad-a063-499c-a6f1-ad4924f5f0ed',
        name: 'Helm Gate in the Sky',
        description: 'Held inside a closed floating crystal fortress.',
        thumbnail: 'helmet-gate-in-the-sky.jpg',
        metadata: {
            instagram: 'https://www.instagram.com/p/CUTEszEMYUb/',
            twitter: 'https://twitter.com/drawnbro/status/1442225745833512960',
            redbubble: 'https://www.redbubble.com/shop/ap/90063166',
            spreadshirt: 'https://drawnbro.myspreadshop.com/helm+gate+in+the+sky?idea=615b0eb475de362b851aaee9',
            mintable: [
                {
                    editionNumber: 1,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495867602621031124372386881081733',
                    mintableUrl: 'https://mintable.app/art/item/Helm-Gate-in-the-Sky-helm-gate---drawnbro-original/bLSTW5MOFMDhrn_'
                },
                {
                    editionNumber: 2,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495853446181679534911855983083892',
                    mintableUrl: 'https://mintable.app/art/item/Helm-Gate-in-the-Sky-helm-gate---drawnbro-original/_FpsKwK8WVBHPYT'
                },
                {
                    editionNumber: 3,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495862439544673618681706688353797',
                    mintableUrl: 'https://mintable.app/art/item/Helm-Gate-in-the-Sky-helm-gate---drawnbro-original/rKiZsy_pzzV-20x'
                },
                {
                    editionNumber: 4,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495852279774534637301422854854677',
                    mintableUrl: 'https://mintable.app/art/item/Helm-Gate-in-the-Sky-helm-gate---drawnbro-original/wF39a7w6SNE6ffv'
                },
                {
                    editionNumber: 5,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495872532446689846012273514475846',
                    mintableUrl: 'https://mintable.app/art/item/Helm-Gate-in-the-Sky-helm-gate---drawnbro-original/hyPuAlResLrE97w'
                },
                {
                    editionNumber: 6,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495842609762992384822500457120819',
                    mintableUrl: 'https://mintable.app/art/item/Helm-Gate-in-the-Sky-helm-gate---drawnbro-original/qE25mTsHmi_eeGN'
                },
                {
                    editionNumber: 7,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495867579439466800093798688036118',
                    mintableUrl: 'https://mintable.app/art/item/Helm-Gate-in-the-Sky-helm-gate---drawnbro-original/jrFIw3WOu2U9r_I'
                },
                {
                    editionNumber: 8,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495848799442731117673051400521877',
                    mintableUrl: 'https://mintable.app/art/item/Helm-Gate-in-the-Sky-helm-gate---drawnbro-original/O7pKzGAftHGA_fn'
                },
                {
                    editionNumber: 9,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495879164042594658059374496851352',
                    mintableUrl: 'https://mintable.app/art/item/Helm-Gate-in-the-Sky-helm-gate---drawnbro-original/mwXbY0NJnRRF6JK'
                },
                {
                    editionNumber: 10,
                    editionTotal: 10,
                    tokenId: '8407815826773092460012303343761068171588464495858743759053238463442369130754',
                    mintableUrl: 'https://mintable.app/art/item/Helm-Gate-in-the-Sky-helm-gate---drawnbro-original/4pkYIhRGsOoKMSL'
                }
            ]
        }
    },
    {
        id: 'eb77dab4-024e-41de-8095-2bc24c6cbd7c',
        name: 'UFO Party',
        description: 'Chilling out like an alien at a UFO party.',
        thumbnail: 'ufo-party.jpg',
        metadata: {
            instagram: 'https://www.instagram.com/p/CU0pXGkssDX/',
            twitter: 'https://twitter.com/drawnbro/status/1446947659021946881',
            redbubble: 'https://www.redbubble.com/shop/ap/91502476?ref=studio-promote',
            spreadshirt: 'https://drawnbro.myspreadshop.com/ufo+party?idea=6162cc19dc5b1108ab5b19d2'
        }
    },
    {
        id: '7905b8b8-477f-4159-9dba-964065f8818b',
        name: 'DB Connections',
        description: 'The lines that connect everything.',
        thumbnail: 'db-lines.jpg',
        metadata: {
            redbubble: 'https://www.redbubble.com/shop/ap/92357911?ref=studio-promote',
            spreadshirt: 'https://spreadshop-admin.spreadshirt.com/drawnbro/db+connections?idea=616dd7fb4bfb2a3e3092f141'
        }
    },
    {
        id: 'a1d23abd-89cf-48a9-909e-0febceebeaab',
        name: 'Earth-C',
        description: 'Beyond the sky is the eye of Earth.',
        thumbnail: 'earth-c.jpg',
        metadata: {
            instagram: "https://www.instagram.com/p/CV51UCRsNni/",
            twitter: "https://twitter.com/drawnbro/status/1456703560431935490",
            redbubble: "https://www.redbubble.com/shop/ap/93929383?ref=studio-promote"
        }
    },
    {
        id: '29a06783-d460-4634-8e78-e7702b4b08f0',
        name: 'Charging Cluster',
        description: 'Charging through the earth.',
        thumbnail: 'scifi-world.jpg',
        metadata: {
            instagram: "https://www.instagram.com/p/CaNqbEYs84I/",
            redbubble: "https://www.redbubble.com/shop/ap/102493380?ref=studio-promote"
        }
    },
    {
        id: 'e85a5d3a-7196-4112-8a62-cf527a1c1923',
        name: 'Lightning Clash',
        description: 'The clash under the moonlight.',
        thumbnail: 'rocky-moon-light.jpg',
        metadata: {
            redbubble: "https://www.redbubble.com/shop/ap/102494861?ref=studio-promote",
            spreadshirt: "https://drawnbro.myspreadshop.com/lightning+clash?idea=6212a9e2fa30da23db36ae64"
        }
    }
]